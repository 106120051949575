.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  }

.header, .footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 2% ;
  padding-bottom: 2%;
  /* height: 20%; */
  min-width: 300px;
}

/* .footer {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 2% ;
  padding-bottom: 2%;
  min-width: 300px;
  position: absolute;
    bottom: 0px;
    width: 100%;
} */

.input{
  margin-bottom: 5%;
  margin-top: 5%;
  text-align: left;
}

.buttonLBX{
  margin-bottom: 2%;
  margin-top: 2%;
}

.a{
  margin-right: 10%;
  margin-left: 20%;
}

.artikel{
  width: 50%;
  text-align: center;
  padding-top: 3%;
}

/* .artikel h3{
  text-align: left;
} */

.artikel p{
  text-align: left;
  margin-bottom: 2%;
}

.artikelslider{
  width:50%;
  text-align: center;
}

.border{
  box-sizing: border-box;
  border-radius: 5px;
}

.button {
  background-color: #282c34;
  color: white;
  width: 90%;
  padding-bottom: 5px;
  margin-bottom: 2px;
}

.button1{
  margin-bottom: 2px;
}

.buttonExtended {
  background-color: #282c34;
  color: white;
  width: 40%;
  margin-left: 2%;
  margin-right:2%;
  margin-top: 2%;
}

.buttonlogin{
  background-color: #282c34;
  margin-top: 2%;
  color: white;
  width: 80px;
}

.buttonlogout{
    background-color: #282c34;
    color: white;
    width: 130px;
    height: 50px;
    font-size: large;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-left: 25%;
    margin-right: 25%;
    /* display: block; 
    margin: auto;  */
}

.buttonlogoutmedia{
  background-color: #282c34;
  color: white;
  width: 130px;
  height: 50px;
  font-size: large;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 32%;
  margin-right: 25%;
}

.buttonsincontent{
  background-color: #282c34;
  color: white;
  width: 20%;
  margin-top: 1%;
  margin-bottom: 2%;
}

.buttonreg{
  background-color: #282c34;
  color: white;
  width: 120px;
  margin-top: 1%;
  margin-bottom: 2%;
}

.buttonsearch{
    background-color: #282c34;
    color: white;
    width: 100px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: arial;
    font-size: large;
    height: 58px;
}

.buttonupload{
  background-color: #282c34;
  color: white;
  width: 150px;
  margin-top: 1%;
  margin-bottom: 2%;
}

.cards{
  margin-top: 2%;
  margin-bottom: 2%;
}

.card4{
  text-align: center;
  display: flex;
  flex-direction: column;  
  align-items: center;
}

.cardsall {
  width: 25%;
}

.cardtitle{
  text-overflow: " [..]";
  font-size: 100px;
}

.center{
  text-align: center;
}

.contact{
  text-align: center;
}

p.contactsmall {
  font-size: 3 px;
}
.content {
  max-width: 1150px;
  text-align: left;
  margin: 0 auto;
  min-height: 50vh;
}

.d-block{
  width: 80%;
  background-color: #282c34;
  display: block;
  margin: auto;
}

.eap{
  margin-top: 2%;
  margin-bottom: 2%;
  max-width: 80%;
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2%;
  min-width: 60%;
  text-align: center;
}

.hr{
  margin-left: 20%;
  padding-right: 20%;
  background-color: #ff1c1d;
  margin-right: 20%;
}

.h2{
  text-align: center;
  padding-top: 2%;
  padding-right: 2%;
}

.h3{
  text-align: center;
  padding-top: 5%;
  padding-right: 2%;
}

.IMG{
  height: 180px;
   width: auto !important;
    display: block;
     margin: 0 auto;
     max-width: 100%;
}

.imageonfile{
  margin-top: 4%;
}

.imageonfilebutton{
  margin-top: 20%;
}

.linkreg{
  text-decoration:none;
}

.login{
  max-width: 1150px;
  text-align: left;
  margin: 0 auto;
  height: 160%;
}

.loginh {
  width: 33,33%;
  padding-bottom: 1%;
  padding-top: 1%;
}

.loginhmedia {
  margin-top: 1%;
  padding-bottom: 3%;
}

.loginpicture{
  width: 70px;
  float: right;
}

.loginpicturemedia{
  width: 65px;
  float: right;
}

.logo{
  width: 33,33%;
  padding-bottom: 1%;
  padding-top: 1%;
}

.logreg{
  font-size: 130%;
  max-width: 80%;
  text-align: center;
  margin: 0 auto;
}

.rand {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2%;
  margin-top: 2%;
}

.regformular{
  margin-top: 2%;
  margin-bottom: 2%;
  max-width: 60%;
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2%;
  min-width: 60%;
}

.register{
  max-width: 1150px;
  text-align: left;
  margin: 0 auto;
  padding-top: 2%;
  padding-bottom: 2%;
}

.regmain{
  margin-top: 2%;
  margin-bottom: 2%;
  max-width: 60%;
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2%;
  min-width: 60%;
}

.reihe3{
  text-align: right;
}

.row4{
  float: left;
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
}

.search{
  max-width: 33,33%;
  padding-bottom: 1%;
  padding-top: 1%;
}

.singlecontent{
  max-width: 1150px;
  text-align: left;
  margin: 0 auto;
  padding-top: 2%;
}

.slider{
  width: 100%;
  margin-bottom: 5%;
  background-color: #282c34;
}

.slider1{
  background-color: #282c34;
}

.startdatum {
  padding-right: 5%;
  text-align: center;
  margin: 0 auto;

}

.startzeit{
  padding-left: 5%;
  text-align: center;
  margin: 0 auto;
}

.upload {
  max-width: 1150px;
  text-align: left;
  margin: 0 auto;
  padding-top: 2%;
}

.upload2{
    margin-top: 2%;
    margin-bottom: 2%;
    max-width: 60%;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4%;
    min-width: 60%;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
